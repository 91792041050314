<template>
  <sub-page
      v-model="$store.state.settings.pageData"
      :title="$t('Departments')"
      :subtitle="$t('Structure')"
      icon="mdi-account"
      :header-action="openNewInstitute"
      :header-action-text="$t('wsu.structure.department.add')"
      :no-content-condition="items.length === 0"
      :no-content-text="$t('wsu.structure.department.no_content')"
      :no-content-button-action="openNewInstitute"
      :no-content-button-text="$t('wsu.structure.department.add')"
      fill-height
  >

    <template #default>

      <ws-data-table
          @itemSelect="selectedItem = $event"
          :row-action="selectEntity"
          :items="itemsFiltered"
          :headers="headers"
          :context-actions-select="contextActionsSelect"
          class="mt-5"
      >
        <template #item.name="{item}">
          <h5 :style="`color : ${wsACCENT}`">{{ item.name }}</h5>
          <h5 v-if="item.institute_name || item.faculty_name">{{ item.faculty_name || item.institute_name }}</h5>
        </template>

        <template #item.university_specialties_count="{item}">
          <h5 class="text-center" :style="`color : ${wsACCENT}`"> {{ item.university_specialties_count }}</h5>
        </template>

        <template #item.teachers_count="{item}">
          <h5 class="text-center" :style="`color : ${wsACCENT}`"> {{ item.teachers_count }}</h5>
        </template>

      </ws-data-table>
    </template>



    <template #dialog>

      <ws-dialog
          v-model="displayDialog"
          :title="$t(!editEntity ? 'wsu.structure.department.add' : 'wsu.structure.department.edit')"
          @save="addInstitute"
          @delete="deleteDepartment"
          :show-delete="editEntity"
          :confirm-delete-code="selectedItem ? selectedItem.uuid : null"
      >

        <ws-text-field
            class="mt-6"
            v-model="entityData.name"
            :label="$t('wsu.structure.department.name.title')"
            :placeholder="$t('wsu.structure.department.name.placeholder')"
            :error="nameError"
            @input="nameError = false"
        />

        <v-expand-transition>
          <ws-select
              v-if="institutesSelect.length > 0"
              class="mt-6"
              @input="handleInstitute"
              v-model="entityData.institute_id"
              :items="institutesSelect"
              :label="$t('Institute')"
              :placeholder="$t('wsu.structure.institute.choose')"
              clearable
          />
        </v-expand-transition>
        <v-expand-transition>
          <ws-select
              v-if="facultiesSelectFiltered.length > 0"
              v-model="entityData.faculty_id"
              :items="facultiesSelectFiltered"
              :label="$t('Faculty')"
              :placeholder="$t('wsu.structure.faculty.choose')"
              clearable
          />
        </v-expand-transition>

      </ws-dialog>

      <ws-dialog
          v-if="displayDeleteDialog"
          v-model="displayDeleteDialog"
          @delete="deleteDepartment"
          :title="$t('wsu.structure.department.delete')"
          display-confirm-delete
          :confirm-delete-code="selectedItem ? selectedItem.uuid : null"
          close-delete
      />

    </template>
  </sub-page>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "specialties",
  data() {
    return {
      items : [],
      displayDialog : false,
      entityData : {},
      institutesSelect : [],
      facultiesSelect : [],
      selectedItem : {},
      displayDeleteDialog : false,
      editEntity : false,
      nameError : false
    }
  },
  computed : {
    ...mapState('avalon' , ['selectedLang']),
    ...mapState('structure' , ['selectedEntityType' , 'selectedEntityId' ]),

    itemsFiltered() {
      let items = this.items
      if ( this.selectedEntityType === 'institute' && this.selectedEntityId ) {
        items = items.filter(el => el.institute_id === this.selectedEntityId)
      }
      if ( this.selectedEntityType === 'faculty' && this.selectedEntityId ) {
        items = items.filter(el => el.faculty_id === this.selectedEntityId)
      }
      return items
    },

    contextActionsSelect() {
      return [
        { text : this.$t('Edit') ,
          value : 'edit' , icon : 'mdi-pencil' ,
          action : () => { this.openEditDepartment() } },
        { text : this.$t('Delete') ,
          value : 'delete' ,
          icon : 'mdi-delete-outline' ,
          action : () => { this.displayDeleteDialog = true }
        }
      ]
    },
    facultiesSelectFiltered() {
      let items = this.facultiesSelect
      if (!items) {
        return []
      }
      if ( this.entityData.institute_id ) {
        items = items.filter(el => el.institute_id === this.entityData.institute_id  )
      }

      return items
    },
    headers() {
      return [
        { text : this.$t('Name') , value : 'name' },
        { text : this.$t('Programs') , value : 'university_specialties_count' , width : 10 , align : 'center' },
        { text : this.$t('Teachers') , value : 'teachers_count' , width : 10  , align : 'center' },
      ]
    }
  },
  watch : {
    entityData : {
      handler(value) {
        if ( value.faculty_id && !value.institute_id ) {
          let faculty = this.facultiesSelect.find(el => el.value === value.faculty_id )
          if ( faculty && faculty.institute_id ) {
            this.entityData.institute_id = faculty.institute_id
            this.entityData = this.COPY(this.entityData)
          }
        }
      },
      deep : true
    }
  },
  methods : {
    ...mapActions('structure' , [ 'GET_DEPARTMENTS' , 'ADD_DEPARTMENT' , 'DELETE_DEPARTMENT' ]),

    selectEntity(item) {
      this.$store.state.structure.returnEntityType.push(this.$store.state.structure.selectedEntityType)
      this.$store.state.structure.returnEntityId.push(this.$store.state.structure.selectedEntityId)
      this.$store.state.structure.returnEntityName.push(this.$store.state.structure.selectedEntityName)

      this.$store.state.structure.selectedEntityType = 'department'
      this.$store.state.structure.selectedEntityId = item.uuid
      this.$store.state.structure.selectedEntityName = item.name
      this.$router.push(this.businessDashLink(`structure/specialties?entity=department&uuid=${item.uuid}&name=${item.name}`))
    },

    // AJAX
    async addInstitute() {
      if ( !this.entityData.name ) {
        this.nameError = true
        return this.notify(this.$t('wsu.structure.department.name.error') , 'warning')
      }
      this.entityData.lang = this.selectedLang

      let result = await this.ADD_DEPARTMENT(this.entityData)
      if ( !result ) {
        this.notify(this.$t('NetworkError'))
        return
      }
      if ( !this.editEntity ) {
        this.items.push(result)
        this.notify(this.$t('wsu.structure.department.created') , 'success')
      } else {
        let index = this.items.findIndex(el => el.uuid === result.uuid )
        if (index === -1 ) {
          return
        }
        this.items[index] = result
        this.items = this.COPY(this.items)
        this.notify(this.$t('ChangesSaved'))
      }
      this.displayDialog = false
    },
    async deleteDepartment() {
      let result = await this.DELETE_DEPARTMENT(this.selectedItem.uuid)
      if ( !result ) {
        return this.notify(this.$t('NetworkError'))
      }
      let index = this.items.findIndex(el => el.uuid === this.selectedItem.uuid )
      if (index === -1 ) {
        return
      }
      this.items.splice(index , 1)
      this.notify(this.$t('wsu.structure.department.deleted') )
      this.displayDeleteDialog = false
      this.displayDialog = false
    },
    // Methods
    openNewInstitute() {
      this.editEntity = false
      this.entityData = {}

      if ( this.$store.state.structure.selectedEntityType === 'institute') {
        this.entityData.institute_id = this.$store.state.structure.selectedEntityId
      }

      if ( this.$store.state.structure.selectedEntityType === 'faculty') {
        this.entityData.faculty_id = this.$store.state.structure.selectedEntityId

        if ( this.entityData.faculty_id && !this.entityData.institute_id ) {
          let faculty = this.facultiesSelect.find(el => el.value === this.entityData.faculty_id )
          if ( faculty && faculty.institute_id ) {
            this.entityData.institute_id = faculty.institute_id
            this.entityData = this.COPY(this.entityData)
          }
        }
      }

      this.displayDialog = true
    },
    openEditDepartment() {
      this.entityData = this.COPY(this.selectedItem)
      this.editEntity = true
      this.displayDialog = true
    },
    // Technical
    handleInstitute(value) {
      if ( !value ) {
        this.entityData.faculty_id = null
        this.entityData = this.COPY(this.entityData)
      }
    },
    async initPage() {
      let result = await this.GET_DEPARTMENTS()
      this.items = result.departments || []
      this.institutesSelect = result.institutes_select || []
      this.facultiesSelect = result.faculties_select || []
    },
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>